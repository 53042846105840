<template>
  <div class="media">
    <my-swiper :bannerList="bannerList"></my-swiper>
    <div class="yxj-container">
      <div class="product-container page-container swer">
        <div class="title-wrapper" v-if="!$store.getters.mobile">
          <h1 class="title-label"><span>{{pageObj.title}}</span></h1>
          <p class="sub-title-label"><span>{{pageObj.info}}</span></p>
        </div>
        <div
          class="qr-center mobile-qr box"
          v-else
        >
          <div class="wx-tips">{{pageObj.title}}</div>
          <div class="wx-label">
            <p>{{pageObj.info}}</p>
          </div>
        </div>
        <div class="fade1-box" v-if="!$store.getters.mobile">
          <div
            class="fade1-item yxj-center"
            v-for="(item, index) in fade1List"
            :key="index"
          >
            <h1>{{item.title}}</h1>
            <div class="box">
              <el-popover
                placement="bottom"
                trigger="hover"
                :disabled="$store.getters.nativeInfo.version == 'Android' || $store.getters.nativeInfo.version == 'Iphone'"
                :visible-arrow="false"
                v-for="(schild, sindex) in item.child"
                :key="sindex"
              >
                <img
                  style="width: 173px;height: 173px"
                  :src="schild.img"
                  :alt="schild.name"
                >
                <p
                  slot="reference"
                  :style="schild.align == 'center' ? 'text-align: center' : ''"
                  @click="showDialog(schild.img, schild.name)"
                >{{schild.name}}</p>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="media-wrapper" v-else>
          <!-- 父循环 -->
          <div class="media-item" v-for="(item, index) in fade1List" :key="index" :class="{active: clickName == index}" @click="clickFather(item, index)">
            <div class="title-box">
              {{item.title}}<i class="alow"></i>
            </div>
            <div class="child-box" :style="clickName == index ? fatherBox : 'height: 0'">
              <div :id="'child'+index">
                <!-- 子循环 -->
                <div class="media-child" 
                  :class="{no: (indexChild == item.child.length - 1) || (indexChild+1) % 7 == 0}" 
                  v-for="(itemChild, indexChild) in item.child" 
                  :key="itemChild.name" 
                  @click.stop="clickChild(itemChild, indexChild, index)" 
                  v-if="itemChild.show"
                >
                  <!-- 子头部 -->
                  <div class="child-title-box">
                    <div class="left">
                      <img :src="itemChild.mobileImg" alt="">
                      <div class="title">{{itemChild.name}}</div>
                    </div>
                    <div class="close" :class="{active: childClickName == itemChild.name}" @click.stop="closeMenu(itemChild, indexChild, index)"><div>{{childClickName == itemChild.name ? '收起' : '关注'}}</div></div>
                  </div>
                  <!-- 子内容 -->
                  <div class="lchild-item" :style="childClickName == itemChild.name ?  ChildBox : 'height: 0'">
                    <div class="box" :id="'l'+index+indexChild">
                      <h3>
                        <span>方法 1：</span>
                        长按二维码保存本地，打开微信扫一扫-选择相册
                      </h3>
                      <h3>
                        <span>方法 2：</span>
                        打开微信-添加公众号-搜索「{{itemChild.subName}}」
                      </h3>
                      <img class="lchild-img" :src="itemChild.img" alt="">
                    </div>
                  </div>
                </div>
                <div class="pagination" v-if="item.child.length > 7" @click.stop>
                  <div class="item" :class="{act: pageSize == ls+1}" v-for="(item, ls) in item.child.length / 7" :key="ls" @click="changePage(ls, index)">{{ls+1}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="yxj-wechart-dialog"
      width="369px"
      :modal-append-to-body="false"
      :lock-scroll="false"
      :visible.sync="wechart"
    >
      <img :src="weChartIcon">
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Download',
  data () {
    return {
      pageObj: {
        title: '新媒体',
        info: '移动端影响力领先的医学新媒体矩阵，为医生提供临床、政策、医学人文、职场等资讯服务。'
      },
      bannerList: [
        {
          to: '/media',
          img: require('@/assets/images/banner03.jpg'),
          mobileImg: require('@/assets/images/banner03m.png'),
          info: '2012年8月，首届纸刊《医学界》杂志面世'
        },
        {
          to: '/media',
          img: require('@/assets/images/banner13.jpg'),
          mobileImg: require('@/assets/images/banner13m.png'),
          info: '2019年6月，ADA年会医学界现场访谈'
        },
        {
          to: '/media',
          img: require('@/assets/images/banner14.jpg'),
          mobileImg: require('@/assets/images/banner14m.png'),
          info: '2020年9月3日，肺癌规范诊疗基层巡讲启动'
        },
        {
          to: '/media',
          img: require('@/assets/images/banner17.jpg'),
          mobileImg: require('@/assets/images/banner17m.png'),
          info: '2020年9月，CSCO年会特别现场'
        },
        {
          to: '/media',
          img: require('@/assets/images/banner21.jpg'),
          mobileImg: require('@/assets/images/banner21m.png'),
          info: '2020年9月25日遵义，“全力抗疫 铿锵基层”第二届基层创新案例颁奖盛典',
          mobileInfo: '2020年9月25日遵义，“全力抗疫 铿锵基层”第二届基层颁奖盛典'
        }
      ],
      fade1List: [
        {
          title: '临床',
          child: [
            {
              name: '心血管',
              subName: '医学界心血管频道',
              img: require('@/assets/images/dgroup_2.jpg'),
              mobileImg: require('@/assets/images/mobile_t4.png'),
              show: true
            },
            {
              name: '肿瘤',
              subName: '医学界肿瘤频道',
              img: require('@/assets/images/dgroup_1.jpg'),
              mobileImg: require('@/assets/images/mobile_t5.png'),
              show: true
            },
            {
              name: '精神',
              subName: '医学界精神病学频道',
              img: require('@/assets/images/dgroup_10.jpg'),
              mobileImg: require('@/assets/images/mobile_t6.png'),
              show: true
            },
            {
              name: '消化肝病',
              subName: '医学界消化肝病频道',
              img: require('@/assets/images/dgroup_5.jpg'),
              mobileImg: require('@/assets/images/mobile_t7.png'),
              show: true
            },
            {
              name: '儿科',
              subName: '医学界儿科频道',
              img: require('@/assets/images/dgroup_8.jpg'),
              align: 'center',
              mobileImg: require('@/assets/images/mobile_t8.png'),
              show: true
            },
            {
              name: '皮肤',
              subName: '医学界皮肤频道',
              img: require('@/assets/images/dgroup_6.jpg'),
              align: 'center',
              mobileImg: require('@/assets/images/mobile_t9.png'),
              show: true
            },
            {
              name: '血液',
              subName: '医学界血液频道',
              img: require('@/assets/images/dgroup_11.jpg'),
              align: 'center',
              mobileImg: require('@/assets/images/mobile_t10.png'),
              show: true
            },
            {
              name: '内分泌',
              subName: '医学界内分泌频道',
              img: require('@/assets/images/dgroup_3.jpg'),
              mobileImg: require('@/assets/images/mobile_t11.png'),
              show: true
            },
            {
              name: '麻醉',
              subName: '医学界麻醉频道',
              img: require('@/assets/images/dgroup_16.jpg'),
              mobileImg: require('@/assets/images/mobile_t12.png'),
              show: true
            },
            {
              name: '检验',
              subName: '医学界检验频道',
              img: require('@/assets/images/dgroup_17.jpg'),
              mobileImg: require('@/assets/images/mobile_t13.png'),
              show: true
            },
            {
              name: '影像诊断',
              subName: '医学界影像诊断与介入频道',
              img: require('@/assets/images/dgroup_18.jpg'),
              mobileImg: require('@/assets/images/mobile_t14.png'),
              show: true
            },
            {
              name: '护理',
              subName: '医学界护理频道',
              img: require('@/assets/images/dgroup_19.jpg'),
              align: 'center',
              mobileImg: require('@/assets/images/mobile_t15.png'),
              show: true
            },
            {
              name: '骨科',
              subName: '医学界骨科频道',
              img: require('@/assets/images/dgroup_20.jpg'),
              align: 'center',
              mobileImg: require('@/assets/images/mobile_t16.png'),
              show: true
            },
            {
              name: '呼吸',
              subName: '医学界呼吸频道',
              img: require('@/assets/images/dgroup_12.jpg'),
              align: 'center',
              mobileImg: require('@/assets/images/mobile_t17.png'),
              show: true
            },
            {
              name: '急重症',
              subName: '医学界急诊与重症频道',
              img: require('@/assets/images/dgroup_13.jpg'),
              mobileImg: require('@/assets/images/mobile_t18.png'),
              show: true
            },
            {
              name: '神经',
              subName: '医学界神经病学频道',
              img: require('@/assets/images/dgroup_9.jpg'),
              mobileImg: require('@/assets/images/mobile_t19.png'),
              show: true
            },
            {
              name: '临床药学',
              subName: '医学界临床药学频道',
              img: require('@/assets/images/dgroup_15.jpg'),
              mobileImg: require('@/assets/images/mobile_t20.png'),
              show: true
            },
            {
              name: '风湿肾病',
              subName: '医学界风湿与肾病频道',
              img: require('@/assets/images/dgroup_4.jpg'),
              mobileImg: require('@/assets/images/mobile_t21.png'),
              show: true
            },
            {
              name: '感染',
              subName: '医学界感染频道',
              img: require('@/assets/images/dgroup_14.jpg'),
              align: 'center',
              mobileImg: require('@/assets/images/mobile_t22.png'),
              show: true
            },
            {
              name: '外科',
              subName: '医学界外科频道',
              img: require('@/assets/images/dgroup_21.jpg'),
              align: 'center',
              mobileImg: require('@/assets/images/mobile_t23.png'),
              show: true
            },
            {
              name: '妇产',
              subName: '医学界妇产科频道',
              img: require('@/assets/images/dgroup_7.jpg'),
              align: 'center',
              mobileImg: require('@/assets/images/mobile_t24.png'),
              show: true
            }
          ]
        },
        {
          title: '资讯',
          child: [
            {
              name: '医学界',
              subName: '医学界',
              img: require('@/assets/images/cgroup_2.jpg'),
              mobileImg: require('@/assets/images/mobile_t1.png'),
              show: true
            },
            {
              name: '医学界智库',
              subName: '医学界智库',
              img: require('@/assets/images/dgroup_22.jpg'),
              mobileImg: require('@/assets/images/mobile_t2.png'),
              show: true
            },
            {
              name: '基层卫生健康',
              subName: '基层卫生健康',
              img: require('@/assets/images/dgroup_23.jpg'),
              mobileImg: require('@/assets/images/mobile_t3.png'),
              show: true
            }
          ]
        },
        {
          title: '服务',
          child: [
            {
              name: '医生站',
              subName: '医学界医生站',
              img: require('@/assets/images/cgroup_1.jpg'),
              mobileImg: require('@/assets/images/mobile_t25.png'),
              show: true
            },
            {
              name: '医学界书店',
              subName: '医学界书店',
              img: require('@/assets/images/dgroup_24.jpg'),
              mobileImg: require('@/assets/images/mobile_t26.png'),
              show: true
            },
            {
              name: '医学界医考',
              subName: '医学界医考',
              img: require('@/assets/images/dgroup_26.jpg'),
              mobileImg: require('@/assets/images/mobile_t27.png'),
              show: true
            }
          ]
        },
        {
          title: '科普',
          child: [
            {
              name: '癌友会',
              subName: '医学界癌友会',
              img: require('@/assets/images/dgroup_25.jpg'),
              mobileImg: require('@/assets/images/mobile_t28.png'),
              show: true
            },
            {
              name: '健康生活',
              subName: '医学界健康生活',
              img: require('@/assets/images/dgroup_27.jpg'),
              mobileImg: require('@/assets/images/mobile_t29.png'),
              show: true
            }
          ]
        }
      ],
      wechart: false,
      weChartIcon: '',
      clickName: 0,
      childClickName: '医学界',
      fatherBox: {
        height: ''
      },
      ChildBox: {
        height: ''
      },
      pageSize: 1
    }
  },
  computed: {
  },
  created () {
    if(this.$store.getters.mobile){
      this.initFade1List()
      this.fade1List.forEach((res) => {
        if(res.child.length > 7){
          res.child.forEach((item,child) => {
            child > 6 ? item.show = false : null
          })
        }
      })
      this.$nextTick(() =>{
        this.fatherBox.height = document.getElementById('child0').offsetHeight+'px'
        this.ChildBox.height = document.getElementById('l00').offsetHeight + 12+'px'
      })
    }
    window.addEventListener('resize', () => {
      this.fatherBox.height = 'auto';
      this.ChildBox.height = 'auto';
    })
  },
  methods: {
    showDialog (img, name) {
      this.wechart = true
      this.weChartIcon = img
      window._hmt && window._hmt.push(['_trackEvent', name, 'click'])
    },
    initFade1List () {
      const arr = this.fade1List[1]
      this.fade1List[1] = this.fade1List[0]
      this.fade1List[0] = arr
    },
    clickFather(item, index) {
      this.clickName == index ? this.clickName = null : this.clickName = index;
      this.fatherBox = {
        height: document.getElementById('child'+index).offsetHeight+'px'
      }
    },
    clickChild(item, indexChild, index) {
      this.childClickName = item.name
      console.log('l'+index+indexChild)
      this.ChildBox = {
        height: document.getElementById('l'+index+indexChild).offsetHeight + 12 +'px'
      }
      // setTimeout(res => {
      //   this.fatherBox = {
      //     height: document.getElementById('child'+index).offsetHeight+'px'
      //   }
      // }, 400)
      this.fatherBox = {
        height: 'auto'
      }

    },
    closeMenu(item, indexChild, index) {
      this.childClickName == item.name ? this.childClickName = null : this.clickChild(item, indexChild, index);
      setTimeout(() => {
        if(this.childClickName == null){
          this.fatherBox = {
            height: document.getElementById('child'+index).offsetHeight+'px'
          }
        }
      }, 400)
    },
    // 切换page
    changePage(index, fatherIndex) {
      this.pageSize = index+1;
      // 还原显示项
      this.fade1List.forEach((res) => {
        res.child.forEach((item) => {
          item.show = true
        })
      })
      // 按分页添加显示项
      this.fade1List[fatherIndex].child.forEach((item, index) => {
        if(index < (this.pageSize - 1) * 7 || index >= this.pageSize * 7){
          item.show = false
        }
      })
      // 重新计算父高度
      setTimeout(() => {
        this.fatherBox = {
          height: document.getElementById('child'+fatherIndex).offsetHeight+'px'
        }
      }, 400)
    }
  },
  watch: {
    '$store.getters.mobile' (val) {
      val ? this.initFade1List() : Object.assign(this.$data, this.$options.data())
      this.wechart = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
